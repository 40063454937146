<template>
  <div class="login">
    <img class="login_bg" src="@/assets/images/login_bg.png" alt="" />
    <div class="title">
      <div class="back_icon" @click="handelBack()">
        <img src="@/assets/images/back_icon.png" alt="" />
      </div>
      登录
    </div>
    <img class="logo" src="@/assets/images/logo.png" alt="" />
    <div class="input_box">
      <div class="item">
        <input
          type="text"
          v-model="userName"
          placeholder="请输入用户名/手机号"
        />
      </div>
      <div class="item">
        <input
          type="password"
          v-model="password"
          placeholder="请输入8~24位密码"
        />
      </div>
      <div
        class="login_btn"
        @click="handelLogin()"
        :class="{ login_active: login_active }"
      >
        登录
      </div>
      <div class="register_btn" @click="handelRegister()">注册</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { Toast } from "vant";
import { getLogin } from "@/request/api";
// import { mapState } from "vuex";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      userName: "",
      password: "",
      login_active: false,
    };
  },
  components: {},
  methods: {
    ...mapMutations(["changeLogin", "loginUser"]),
    handelBack() {
      this.$router.go(-1);
    },
    handelLogin() {
      if (this.userName.trim() && this.password.trim()) {
        let param = {
          name: this.userName,
          pwd: this.password,
        };
        getLogin(param).then((res) => {
          console.log(res);
          if (res.token) {
            this.changeLogin(res.token);
            this.loginUser(JSON.stringify(res.loginUser));
            if (res.loginUser.institutionId == 0) {
              this.$router.push("/perfectInfo");
            } else {
              this.$router.push("/");
            }
          } else {
            console.log(res);
          }
        });

        // this.Http.login({
        //   name: "tea1",
        //   pwd: 123456,
        // }).then((res) => {

        // });
      } else {
        Toast("用户名/手机号和密码不能为空!");
      }
    },
    handelRegister() {
      this.$router.push("/register");

      // this.$store.commit('getToken')
    },
  },
  mounted() {},
  computed: {
    // ...mapState(["token"]),
  },
  watch: {
    password(val) {
      if (val.length != 0) {
        if (this.userName && this.password) {
          this.login_active = true;
        } else {
          this.login_active = false;
        }
      } else {
        this.login_active = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
.login {
  .login_bg {
    width: 100%;
    height: 4rem;
  }

  .title {
    width: 100%;
    height: 0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "PingFangSC-Regular";
    font-size: 0.34rem;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
  }

  .back_icon {
    position: absolute;
    left: 0.45rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .back_icon img {
    width: 0.19rem;
    height: 0.34rem;
  }

  .logo {
    width: 1.94rem;
    height: 1.94rem;
    position: absolute;
    left: 50%;
    top: 3.07rem;
    transform: translateX(-50%);
  }

  .input_box {
    width: 100%;
    padding: 0 0.3rem;
    margin-top: 1.35rem;

    .item {
      border-bottom: 0.01rem solid #e5e5e5;
      padding: 0.3rem 0;
      margin-bottom: 0.33rem;

      input {
        font-size: 0.32rem;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: #333333;
        width: 100%;
        font-size: 0.32rem;
      }

      &::-webkit-input-placeholder {
        color: #999999;
      }
    }

    .login_btn {
      width: 100%;
      height: 0.9rem;
      background: #0067b0;
      opacity: 0.5;
      border-radius: 0.45rem;
      text-align: center;
      line-height: 0.9rem;
      font-size: 0.34rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #ffffff;
      margin: 1rem 0 0.4rem 0;
    }

    .login_active {
      opacity: 1;
    }

    .register_btn {
      width: 100%;
      height: 0.9rem;
      background: #ffffff;
      border-radius: 0.45rem;
      text-align: center;
      line-height: 0.9rem;
      font-size: 0.34rem;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: #0067b0;
      border: 0.02rem solid #0067b0;
    }
  }
}
</style>
